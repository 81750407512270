body{
    margin: 0;
    overflow: hidden;
    background-color: #0f0f12;
    color: whitesmoke;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

*{
    box-sizing: border-box;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #6BC1E1 #ffffff00;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #6BC1E1;
    border-radius: 10px;
    border: 3px solid #ffffff00;
  }